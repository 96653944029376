<template>
	<div class="landing-wrapper">
        <t-header />
		<div class="container space-top-3 space-bottom-2">
			<div class="w-lg-80 mx-lg-auto">
				<div class="mb-4">
					<h1 class="h2">
						Privacy Policy
					</h1>
					<p class="lead">
						Effective: January 4th, 2021
					</p>
				</div>
				<p>
					LATAX Technologies, LLC., doing business as Thriftax (“Thriftax”,
					“we”, “us”, or “our”) is committed to protecting your privacy. We are
					committed to protecting your personal information and your right to
					privacy and follow the terms and practices in this Privacy Policy to
					describe to you our practices regarding the Personal Information (as
					defined below) we collect from users of our website, located at
					www.thriftax.com (the “Website”).
				</p>
				<p>
					To be clear about the terminology we are using, when we use the phrase
					“Personal Information” in this Privacy Policy, we mean information
					that allows someone to identify or contact you, including, for
					example, your name, address, telephone number, e-mail address, as well
					as any other non-public information about you that is associated with
					or linked to any of the foregoing information. When we use the phrase
					“Anonymous Information” in this Privacy Policy, we mean information
					that is not associated with or linked to your Personal Information.
					Anonymous Information does not enable identification of or contact
					with individual persons.
				</p>
				<h3 class="mt-4">
					A Note to Users Outside of the United States
				</h3>
				<p>
					This website is designated for international nationals, required to
					file US taxes and tax-related forms. By visiting this website from
					outside the US U.S. and/or providing us with information, you
					acknowledge and agree that your Personal Information may be processed
					for the purposes identified in the Privacy Policy. In addition, your
					Personal Information may be processed in the country in which it was
					collected and in other countries, including the United States, where
					laws regarding processing of Personal Information may be less
					stringent than the laws in your country. By providing your
					information, you consent to such transfer.
				</p>
				<h3 class="mt-4">
					How We Collect Information
				</h3>
				<h5>Personal Information</h5>
				<p>
					In general, the Personal Information we gather enables us to provide
					the Services to you and helps us learn more about the use of the
					Website and/or Services. The types of information we collect applies
					whether you are a current, prospective or former client. We collect
					Personal Information that you submit to us voluntarily at registration
					and in the course of your use of the Website and/or Services including
					geolocation information and Internet Protocol (“IP”) address (a number
					that is automatically assigned to your computer when you use the
					Internet, which may vary from session to session). We do not collect
					additional Personal Information on former clients, unless it is
					provided to us voluntarily. To use certain features of the Website, we
					require you to create an “Account” by providing your email address and
					a password. After you create an Account, and in order to provide you
					with the tax return filing and associated services, we will ask and
					collect information such as:
				</p>
				<ul class="px-3">
					<li>
						Contact information and identification information, including but
						not limited to names, addresses, emails, phone numbers, passport
						numbers, nationality, social security number or tax identification
						number
					</li>
					<li>
						Income information and other tax-related information including but
						not limited to Income forms, employers and other payers’
						information, expenses, and tax withholdings.
					</li>
					<li>
						Payment information required to pay for Services. Your payment
						information may include names, mean of payment information (such as
						credit card information) and a billing address. All payment
						information is collected and and stored by Braintree, a PayPal inc.
						company. You may find Braintree’s privacy policy
						<a
							target="_blank"
							href="https://www.braintreepayments.com/legal/braintree-privacy-policy"
							>here</a
						>.
					</li>
					<li>
						Your tax refund processing information including a US bank account
						or a physical address you may choose to get your tax refund
						deposited in or sent to.
					</li>
					<li>
						Any other information provided by you to complete your tax return or
						any other Service provided in this site, including information
						provided about yourself, family members and contact information of
						your relatives and non-relatives referrals.
					</li>
				</ul>

				<p>
					When you participate in one of our surveys, we may collect additional
					Personal Information from you. If you participate in a referral
					program, we may ask you for your e-mail, PayPal or Venmo account
					credentials and/or phone number to pay out your referral compensation.
					We recommend that you read the rules for each survey or other program
					that you enter.
				</p>
				<p>
					In addition to the information we collect when you establish an
					account we may collect information if you or a person referred by you
					engages with Thriftax. We collect email addresses subscribe to our
					email communications which may include targeted information for
					services offered by Thriftax or one of its partners. We also collect
					your name and email if you request communication through our Contact
					Us page.
				</p>

				<h5>Anonymous Information</h5>
				<p>
					We collect the following types of Anonymous Information:
				</p>
				<p>
					Information Collected by Our Servers: As is true of most websites, we
					gather certain information automatically and store it in log files.
					Our servers (which are hosted by a secured AWS servers) collect
					information from you, including but not limited to your browser type,
					operating system, geographic location, IP address, domain name, and/or
					a date/time stamp for your visit, clickstream data and referring/exit
					pages. We use this information to analyze trends, administer the
					Website, track users’ movements around the Website, gather demographic
					information about our user base as a whole, and better tailor our
					Services to our users’ needs. For example, some of the information may
					be collected so that when you visit the Website or the Services again,
					it will recognize you.
				</p>
				<p>
					Log Files: As is true of most websites, we gather certain information
					automatically and store it in log files. This information includes IP
					addresses, browser type, Internet service provider (“ISP”),
					referring/exit pages, operating system, date/time stamp, and
					clickstream data. We use this information to analyse trends,
					administer the Services, track users’ movements around the Services,
					gather demographic information about our user base as a whole, and
					better tailor our Services to our users’ needs.
				</p>
				<p>
					Cookies. Like many online services, we use cookies to collect
					information. “Cookies” are small pieces of information that a website
					sends to your computer’s hard drive while you are viewing the website.
					We may use both session Cookies (which expire once you close your web
					browser) and persistent Cookies (which stay on your computer until you
					delete them) to provide you with a more personal and interactive
					experience on our Website. This type of information is collected to
					make the Website more useful to you and to tailor the experience with
					us to meet your special interests and needs. Third parties, such as
					Facebook may use cookies and other storage technologies, to collect or
					receive your information to provide targeted ads. To opt-out of the
					collection and use of information for ad targeting you can use
					https://optout.aboutads.info/. You can also view your Facebook ads
					settings and update your preferences at any time.
				</p>
				<h5>Analytics</h5>
				<p>
					We may partner with third party services, such as ad networks,
					including Facebook, and tracking services, such as Google Analytics,
					to track and analyse Personal Information and Anonymous Information
					from users of our Website. Such third party services may use Cookies
					to help track user behaviour. The use of these Cookies by third
					parties is not covered by our Privacy Policy. Some features that may
					be utilized include but are not limited to remarketing, audiences,
					demographic or location information.
				</p>
				<p>
					We may not have access or control over these Cookies. For example,
					Google Analytics plants a persistent Cookie on your web browser to
					identify you as a unique user the next time you visit the Website.
					This Cookie cannot be used by anyone but Google. Google’s ability to
					use and share information collected by Google Analytics about your
					visits to the Website is restricted by the Google Analytics Terms of
					Service and the Google Privacy Policy. We may also obtain Information
					from Partners, Business Associates and Unrelated Entities and combine
					that with Personal Information we collect about you.
				</p>
				<h5>Information Collected Automatically</h5>
				<p>
					When a person visits any of our sites, or opens one of our emails, a
					variety of information is automatically collected through standard web
					technologies. Some of this data is Anonymous Information. This data
					may be linked with your Account and Personal Information to better
					assist our clients. Automatically-collected information might include:
					the operating system on your computer, the type of Web browser you are
					using, your IP address, and the links you followed to enter and leave
					our site.
				</p>
				<h3 class="mt-4">
					How We Use Information
				</h3>
				<p>
					We do not sell, rent or trade your Personal Information with third
					party companies.
				</p>
				<h5>Personal Information</h5>
				<p>We will use your Personal Information to:</p>
				<p class="py-0 my-2">Identify you as a user in our system;</p>
				<p class="py-0 my-2">
					Facilitate the creation of, secure, and administer your Account;
				</p>
				<p class="py-0 my-2">
					Provide you with Services, including personalizing the Services for
					you, as required to provide with a personal tax-related services;
				</p>
				<p class="py-0 my-2">Provide you with customer support;</p>
				<p class="py-0 my-2">
					Market to you our products and services (and those of third parties)
					that we believe may interest you;
				</p>
				<p class="py-0 my-2">
					Help understand your needs and tailor the features and content of the
					Service to you;
				</p>
				<p class="py-0 my-2">
					Respond to your requests, resolve disputes and/or troubleshoot
					problems;
				</p>
				<p class="py-0 my-2">
					Verify your compliance with your obligations in our Client Agreement
					and Website Terms and Conditions
				</p>
				<p class="py-0 my-2">
					Improve the quality of the Website and the Services; and
				</p>
				<p class="py-0 my-2">
					Communicate with you about the Website and the Services.
				</p>
				<p class="py-0 my-2">
					We may also use your Personal Information to create Anonymous
					Information records
				</p>
				<h5>Anonymous Information</h5>
				<p>
					Generally, we use Anonymous Information in statistical analysis to
					help us analyze patterns in the use of our Services. We may, through
					our use of Cookies, keep you logged into our Website, collect your
					click-stream data, tailor web content to you and personalize your
					experience on the Website. We may also use your Anonymous Information
					to improve our internal operations, notify visitors of updates to our
					Website and Services, and software and customize the content and
					layout of the Website and/or Service. However, we reserve the right to
					use Anonymous Information for any purpose.
				</p>
				<h3 class="mt-4">
					How We Disclose Information
				</h3>
				<p>We disclose certain Personal Information:</p>

				<p>
					To a parent company, any subsidiaries, joint ventures, or other
					companies under a common control (collectively, “Affiliates”), in
					which case we will require our Affiliates to honor this Privacy
					Policy;
				</p>
				<p>
					In connection with or during negotiation of any merger, financing,
					acquisition or dissolution, transaction or proceeding involving sale,
					transfer, divestiture, or disclosure of all or a portion of our
					business or assets. In the event of an insolvency, bankruptcy, or
					receivership, Personal Information may also be transferred as a
					business asset. If another company acquires our company, business, or
					assets, that company will possess the Personal Information collected
					by us and will assume the rights and obligations regarding your
					Personal Information as described in this Privacy Policy;
				</p>
				<p>
					If we believe in good faith that such disclosure is necessary to (a)
					resolve disputes, investigate problems, or enforce our Terms and
					Conditions; (b) comply with relevant laws or to respond to requests
					from law enforcement or other government officials relating to
					investigations or alleged illegal activity, in which case we can (and
					you authorize us to) disclose Personal Information without subpoenas
					or warrants served on us; or (c) protect and defend our rights or
					property or the rights and property of you or third parties; and/or
					(d) to investigate or assist in preventing any violation or potential
					violation of the law, this Privacy Policy, or our Website Terms and
					Conditions.
				</p>
				<p>
					Disclosure to Third Party Companies. We may enter into agreements with
					companies to help us serve targeted advertisements. These companies
					may use data about you in order to provide ads that may be of interest
					and relevant to you and others. Former client information may be
					provided in order to omit from campaigns. To learn more about this
					behavioral advertising practice or to opt out of some network
					advertising programs, you can visit optout.networkadvertising.org. We
					do not sell, rent or trade your Personal Information with third party
					companies.
				</p>
				<h5>Anonymous Information</h5>
				<p>
					We reserve the right to disclose Anonymous Information publicly
					without restriction.
				</p>
				<h5>Feedback</h5>

				<p>
					If you provide feedback to us, we may use and disclose such feedback
					in accordance with the terms in our Terms and Conditions, provided we
					do not associate such feedback with your Personal Information. We may
					publish user feedback from time to time, provided that we will obtain
					your consent prior to posting your feedback. We will collect any
					information contained in such feedback and will treat the Personal
					Information in it in accordance with this Privacy Policy, provided
					that we may contact you in regards to such feedback.
				</p>
				<h3 class="mt-4">
					Controlling Your Information
				</h3>
				<p>
					You can review and/or change certain parts of your Personal
					Information by logging into the Website and accessing your Account.
					You authorize us to use and disclose any new or modified information
					that you provide in accordance with this Privacy Policy, and you agree
					that we are under no obligation to delete or modify information that
					you have previously chosen to provide us.
				</p>
				<p>
					Certain information is necessary in order for us to provide the
					Website or Services; therefore, if you delete such necessary
					information you may not be able to use the Website or Services. Please
					note that even though you may request the deletion of your Personal
					Information by us, we may be required (by law or otherwise, such as to
					prevent fraud, resolve disputes, troubleshoot problems, or enforce our
					Terms and Conditions) to keep this information and not delete it (or
					to keep this information for a certain time) in which case we will
					comply with your deletion request only after we have fulfilled such
					requirements. When we delete Personal Information, it will be deleted
					from the active database, but may remain in our archives.
				</p>
				<p>
					If you decide at any time that you no longer wish to accept cookies
					from our Service for any of the purposes described above, then you can
					instruct your browser, by changing its settings, to stop accepting
					cookies or to prompt you before accepting a cookie from the websites
					you visit. Consult your browser’s technical information. If you do not
					accept cookies, however, you may not be able to use all portions of
					the Services or all functionality of the Services.
				</p>
				<p>
					We will communicate with you primarily in the form of emails. You can
					manage the frequency and/or opt out of receiving these communications
					by clicking the “Unsubscribe” link at the bottom of each email. Please
					note that even if you unsubscribe or opt-out, we may still send you
					Website and Services related communications (e.g., emails related to
					your account, purchased Services or the Website). You may also sign up
					to receive account updates using text messaging. You can opt in or out
					of the service through your Account at any time.
				</p>
				<h3 class="mt-4">
					Information Disclosed to Third Parties
				</h3>
				<p>
					This Privacy Policy addresses only our use and disclosure of
					information we collect from and/or about you on the Website and
					Services. If you disclose information to others, or authorize us to do
					the same under this Privacy Policy, the use and disclosure
					restrictions contained in this Privacy Policy will not apply to any
					third party. We do not control the privacy policies of third parties,
					and you are subject to the privacy policies of those third parties
					where applicable.
				</p>
				<p>
					The Website may contain content or links to other websites that are
					not owned or controlled by us. We have no control over, do not review
					and are not responsible for the privacy policies of or content
					displayed on such other websites. When you click on such a link, you
					will leave our Website and go to another site. During this process,
					another entity may collect Personal Information or Anonymous
					Information from you. The Website may also contain links to other
					websites controlled by us but which operates under different privacy
					policies. Please review the privacy policy of any new site you visit.
				</p>
				<h3 class="mt-4">
					California Privacy Rights
				</h3>
				<p>
					We will limit the sharing of California residents’ personal
					information upon request, as required by law, by contacting
					info@thriftax.com.
				</p>
				<p>
					California residents have the right to request and receive information
					regarding the third parties we have disclosed their Personal
					Information, including the type of information disclosed. This request
					can be made without charge, by contacting info@thriftax.com.
				</p>
				<h3 class="mt-4">
					How We Respond to Do Not Track Signals
				</h3>
				<p>
					We do not currently respond to “do not track” signals or other
					mechanisms that might enable consumers to opt out of tracking on the
					Service.
				</p>
				<h3 class="mt-4">
					Security of Your Information
				</h3>
				<p>
					We are committed to protecting the security of your Personal
					Information. We use a variety of industry-standard security
					technologies and procedures to help protect your Personal Information
					from unauthorized access, use, or disclosure. We are only using
					secured AWS servers to store and operate our site. We require you to
					confirm your email address upon sign-up to prevent an unauthorized
					party of receiving access to your personal information such as
					information that you are required to provide as part of consuming our
					Services. We also require you to enter a password to access your
					Account. Please do not disclose your Account password to unauthorized
					people. No method of transmission over the Internet, or method of
					electronic storage, is 100% secure, however. Therefore, while we use
					reasonable efforts to protect your Personal Information, we cannot
					guarantee its absolute security.
				</p>
				<h3 class="mt-4">
					Changes to this Privacy Policy
				</h3>
				<p>
					Thiftax reserves the right to make changes to this Privacy Policy from
					time to time for any reason by updating this page. You should consult
					this Privacy Policy regularly for any changes.
				</p>
				<h3 class="mt-4">
					Contacting Thriftax
				</h3>
				<p>
					If you have any questions or concerns or complaints about our Privacy
					Policy or our data collection or processing practices, or if you want
					to report any security violations to us, please contact us at
					info@thriftax.com
				</p>
			</div>
		</div>
        <t-footer />
	</div>
</template>

<script>
export default {
};
</script>
